import translations from "../translations/english.json";

export enum LoginStage {
  LOGGED_OUT,
  LOGGING_IN,
  LOGGED_IN,
  LOGIN_FAILED,
}

export enum Language {
  EN,
  PL,
}

export const LANGUAGES = [Language.EN, Language.PL];

export type Translation = typeof translations;

export type MessageCode = keyof typeof translations.messages | "";
