import { useEffect, useState } from "react";
import Message, { MessageType } from "../../components/Messages/Message";
import InputBox from "../../components/InputComponents/InputBox";
import { RawOrders } from "../../misc/models.allegro";
import { Captcha } from "../../misc/models.pocztex";
import {
  API_BASE,
  fetchWithToken,
  filterObjectByKey,
  formatErrorMessage,
  getOrderDescription,
  getRecipientData,
} from "../../misc/util";
import { CASH_ON_DELIVERY_ID } from "./OrderList";
import "./OrderProcessing.css";
import StatusIndicator from "../../components/Messages/StatusIndicator";
import { MessageCode, Translation } from "../../misc/models";

/** Sends the order details to the given Pocztex API endpoint.
 *  If the request fails, returns `false`.
 *  Else, returns a list of the error messages, if any.
 */
async function submitOrder(
  endpoint: "parcel-draft" | "parcel",
  order: object
): Promise<string[] | null> {
  const res = await fetchWithToken("pocztex", endpoint, "POST", order);
  if (!res) return null;
  return res.data.messages.map((msg: { message: string }) => msg.message);
}

type ValidCaptchas = Record<number, boolean | null>;

export default function OrderProcessing({
  orders: allOrders,
  processing,
  setProcessing,
  content,
}: {
  orders: RawOrders;
  processing: boolean;
  setProcessing: (arg0: boolean) => void;
  content: Translation;
}) {
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [userMessage, setUserMessage] = useState<{
    type: MessageType;
    msg: MessageCode;
  } | null>(null);
  const [orderMessages, setOrderMessages] = useState<string[]>([]);
  const [ordersAreValid, setOrdersAreValid] = useState<boolean | undefined>(
    undefined
  );
  const [captchas, setCaptchas] = useState<Record<number, Captcha>>({});
  const [validCaptchas, setValidCaptchas] = useState<ValidCaptchas>({});

  const processedOrders = localStorage.getItem("acceptedOrders");
  const orders = allOrders.checkoutForms
    .filter(
      (order) =>
        order.delivery.method.id === CASH_ON_DELIVERY_ID &&
        order.fulfillment.status === "NEW" &&
        !processedOrders?.includes(order.id)
    )
    .map(({ id, buyer, delivery, summary, lineItems }) => ({
      id,
      contentDesc: getOrderDescription(lineItems),
      cost: +summary.totalToPay.amount - +delivery.cost.amount,
      recipientData: getRecipientData(delivery.address, buyer.email),
    }));

  useEffect(() => {
    if (!processing) return;
    for (let i = 0; i < orders.length; i++) {
      fetchCaptcha(i);
    }
  }, [processing]);

  useEffect(() => {
    setOrdersAreValid(undefined);
    setValidCaptchas({});
  }, [allOrders]);

  useEffect(() => {
    if (ordersAreValid !== undefined) return;
    clearUserMessage();
    (async () => {
      const result = await validateOrders();
      console.log("Result:", result);
      if (typeof result === "string") {
        setUserMessage({ type: "error", msg: result });
      } else {
        setOrdersAreValid(result);
      }
    })();
  }, [ordersAreValid]);

  async function fetchCaptcha(idx: number) {
    if (captchas[idx]) {
      setCaptchas((curr) => filterObjectByKey(curr, idx));
    }
    const res = await fetchWithToken("pocztex", "captcha");
    if (res) {
      setCaptchas((curr) => ({ ...curr, [idx]: res.data.item }));
    }
  }

  /** Validates each order. Returns `true` if all are valid, else `false`.
   *  If an error is encountered (e.g. connection failure), returns error message as a string.
   */
  async function validateOrders(): Promise<boolean | MessageCode> {
    setUserMessage({ type: "info", msg: "infoValidating" });
    const res = await fetchWithToken(
      "pocztex",
      `${API_BASE}/pocztex/orders`,
      "POST",
      orders
    );
    if (!res?.ok) {
      if (res?.data) {
        setOrderMessages((curr) => [...curr, formatErrorMessage(res.data)]);
      }
      return "errConnectionFailed";
    }
    const errorMessages: string[] = [];
    for (const order of res.data) {
      const invalidFields = await submitOrder("parcel-draft", order);
      if (invalidFields) {
        if (invalidFields.length === 0) continue;
        errorMessages.push(`${order.id}: ${invalidFields.join(", ")}`);
      } else {
        return "errConnectionFailedPocztex";
      }
    }
    if (errorMessages.length === 0) {
      setUserMessage({
        type: "success",
        msg: "infoAllValid",
      });
      return true;
    } else {
      setOrderMessages(errorMessages);
      return false;
    }
  }

  function clearUserMessage() {
    setUserMessage(null);
    setOrderMessages([]);
  }

  async function submitOrders() {
    setClickedSubmit(true);
    setUserMessage({ type: "info", msg: "infoProcessing" });

    setValidCaptchas(
      Object.fromEntries(Object.keys(captchas).map((key) => [key, null]))
    );

    async function _submit() {
      const res = await fetchWithToken(
        "pocztex",
        `${API_BASE}/pocztex/orders?submit=true`,
        "POST",
        orders
      );
      if (!res?.ok) {
        setUserMessage({ type: "error", msg: "errConnectionFailed" });
        if (res?.data) {
          setOrderMessages((curr) => [...curr, formatErrorMessage(res.data)]);
        }
        return;
      }
      setUserMessage({ type: "info", msg: "infoValidatingCAPTCHA" });

      const numOrders = res.data?.length ?? 0;
      const acceptedOrders = [];

      for (let i = 0; i < numOrders; i++) {
        function fail(msg: string) {
          setValidCaptchas((curr) => ({ ...curr, [i]: false }));
          msg = msg.replace(
            "Nieprawidłowy kod CAPTCHA",
            content.orders.captcha.invalidCode
          );
          const partialMsg = `${content.misc.order} ${i + 1}: ${msg}`;
          setOrderMessages((curr) => [...curr, partialMsg]);
        }

        const order = res.data[i];
        const errorMsgs = await submitOrder("parcel", {
          ...order,
          captcha: captchas[i],
        });
        if (!errorMsgs) {
          // Network error
          fail("Could not connect to the Pocztex API.");
        } else if (errorMsgs.length > 0) {
          // Validation error
          fail(errorMsgs.join("\n"));
        } else {
          // Order accepted
          acceptedOrders.push(order.id);
          setValidCaptchas((curr) => ({ ...curr, [i]: true }));
        }
      }
      localStorage.setItem("acceptedOrders", JSON.stringify(acceptedOrders));
      if (acceptedOrders.length === numOrders) {
        // Success
        setUserMessage({ type: "success", msg: "infoOrdersAccepted" });
      } else if (acceptedOrders.length === 0) {
        setUserMessage({ type: "error", msg: "errOrdersRejected" });
      } else {
        const s = numOrders === 1 ? " was" : "s were";
        setUserMessage({
          type: "info",
          msg: "infoSomeOrdersRejected",
        });
        setOrderMessages((curr) => [
          ...curr,
          `${
            numOrders - acceptedOrders.length
          }/${numOrders} order${s} rejected.`,
        ]);
      }
    }
    await _submit();
    setClickedSubmit(false);
  }

  const submitDisabled =
    clickedSubmit ||
    !ordersAreValid ||
    Object.values(captchas).some((captcha) => !captcha.captchaCode);

  function setCaptchaCode(idx: number, captchaCode: string) {
    if (userMessage?.type === "error" || orderMessages.length > 0) {
      clearUserMessage();
    }
    setCaptchas((curr) => ({
      ...curr,
      [idx]: { ...curr[idx], captchaCode },
    }));
  }

  return (
    <>
      <h4>
        {content.orders.processor.status}: {orders.length}
      </h4>
      {processing ? (
        <>
          <div className="vertical container radio-container">
            <Message
              type={userMessage?.type}
              msg={content.messages[userMessage?.msg ?? ""]}
            />
            {orderMessages.map((msg, idx) => (
              <Message key={idx} type="error" msg={msg} />
            ))}
          </div>
          <div className="container radio-container">
            {content.orders.processor.captchaInfo}
          </div>
          {orders.map((_, idx) => (
            <InputCaptcha
              key={idx}
              idx={idx}
              captcha={captchas[idx]}
              validCaptchas={validCaptchas}
              setCaptchaCode={setCaptchaCode}
              fetchCaptcha={fetchCaptcha}
              clickedSubmit={clickedSubmit}
              content={content}
            />
          ))}
          <button
            className="btn2"
            onClick={submitOrders}
            disabled={submitDisabled}
            tabIndex={Object.keys(captchas).length + 1}
          >
            {clickedSubmit
              ? content.messages.infoValidatingCAPTCHA
              : content.misc.submit}
          </button>
        </>
      ) : (
        <>
          <p>{content.orders.processor.disabled}:</p>
          <ol>
            {content.orders.processor.steps.map((step, idx) => (
              <li key={idx}>{step}</li>
            ))}
          </ol>
        </>
      )}
    </>
  );
}

const InputCaptcha = ({
  idx,
  captcha,
  validCaptchas,
  setCaptchaCode,
  fetchCaptcha,
  clickedSubmit,
  content,
}: {
  idx: number;
  captcha: Captcha | undefined;
  validCaptchas: ValidCaptchas;
  setCaptchaCode: (arg0: number, arg1: string) => void;
  fetchCaptcha: (arg0: number) => void;
  clickedSubmit: boolean;
  content: Translation;
}) => (
  <div className="spaced-out container">
    {captcha && (
      <button
        className="btn2"
        onClick={() => {
          fetchCaptcha(idx);
          setCaptchaCode(idx, "");
        }}
      >
        {content.orders.captcha.newImage}
      </button>
    )}
    <InputBox
      value={captcha?.captchaCode ?? ""}
      setValue={(val) => setCaptchaCode(idx, val)}
      disabled={clickedSubmit}
      tabIndex={idx + 1}
    >
      {captcha ? (
        <img src={captcha.path} alt="CAPTCHA challenge" />
      ) : (
        <span>{content.orders.captcha.generating}...</span>
      )}
    </InputBox>
    {validCaptchas.hasOwnProperty(idx) && (
      <StatusIndicator status={validCaptchas[idx]} />
    )}
  </div>
);
