import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Message from "../../components/Messages/Message";
import InputBox from "../../components/InputComponents/InputBox";
import InputRadio from "../../components/InputComponents/InputRadio";
import { fetchWithToken, POCZTEX_API_BASE } from "../../misc/util";
import "./Lookup.css";
import { MessageCode, Translation } from "../../misc/models";

const API_NAMES = ["allegro", "pocztex"];

const API_BASES = ["https://api.allegro.pl/", POCZTEX_API_BASE + "/"];

const METHODS = ["POST", "PUT", "GET", "DELETE"];

export default function Lookup({
  setPage,
  content,
}: {
  setPage: (arg0: keyof Translation) => void;
  content: Translation;
}) {
  const [path, setPath] = useState("");
  const [body, setBody] = useState("");
  const [output, setOutput] = useState("");
  const [selectedAPI, setSelectedAPI] = useState<number>(0);
  const [selectedMethod, setSelectedMethod] = useState<string>("GET");
  const [errorMessage, setErrorMessage] = useState<MessageCode>("");

  const service = API_NAMES[selectedAPI];

  async function makeRequest() {
    let parsed = undefined;
    if (body) {
      try {
        parsed = JSON.parse(body);
      } catch {
        setErrorMessage("errJSON");
        return;
      }
    }
    setOutput(content.misc.fetching + "...");
    const res = await fetchWithToken(service, path, selectedMethod, parsed);
    if (res) {
      console.info(res.status, res.data);
      const formatted = JSON.stringify(
        {
          [content.lookup.req.status]: `${res.status} ${res.statusText}`,
          [content.lookup.req.body]: res.data,
        },
        null,
        2
      );
      setOutput(formatted);
    } else {
      setOutput("Error! Could not connect to the API.");
    }
  }

  useEffect(() => {
    setPage("lookup");
  }, [content]);

  useEffect(() => {
    if (selectedMethod === "GET") {
      setBody("");
    }
  }, [selectedMethod]);

  useEffect(() => {
    setSelectedMethod("GET");
  }, [selectedAPI]);

  useEffect(() => {
    setErrorMessage("");
  }, [body]);

  return (
    <>
      <p>{content.lookup.body}</p>
      <div className="container radio-container">
        <InputRadio
          value={0}
          currentValue={selectedAPI}
          onChange={setSelectedAPI}
        >
          Allegro API
        </InputRadio>
        <InputRadio
          value={1}
          currentValue={selectedAPI}
          onChange={setSelectedAPI}
        >
          Pocztex API
        </InputRadio>
      </div>
      {selectedAPI === 1 && (
        <div className="container radio-container">
          {METHODS.map((method) => (
            <InputRadio
              key={method}
              currentValue={selectedMethod}
              onChange={setSelectedMethod}
            >
              {method}
            </InputRadio>
          ))}
        </div>
      )}
      {selectedMethod !== "GET" && (
        <div className="spaced-out container">
          <InputBox value={body} setValue={setBody}>
            {content.lookup.requestBody}:
          </InputBox>
        </div>
      )}
      <Message type="error" msg={content.messages[errorMessage]} />
      <div className="spaced-out container">
        <InputBox
          value={path}
          setValue={setPath}
          onSubmit={makeRequest}
          autoFocus
        >
          {API_BASES[selectedAPI]}
        </InputBox>
        <button className="btn2" onClick={makeRequest}>
          {content.lookup.send}
        </button>
      </div>
      {output && (
        <>
          <div id="output">
            <pre>{output}</pre>
          </div>
          <button
            className="btn2"
            id="clearOutput"
            onClick={() => setOutput("")}
          >
            {content.lookup.clear}
          </button>
        </>
      )}
    </>
  );
}
