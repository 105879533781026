import CopyElem from "../../components/CopyElem/CopyElem";
import InputRadio from "../../components/InputComponents/InputRadio";
import { Translation } from "../../misc/models";
import { Order, RawOrders } from "../../misc/models.allegro";
import { formatPrice } from "../../misc/util";
import "./OrderList.css";

export const CASH_ON_DELIVERY_ID = "97286096-eb28-40f9-9efc-95ecbb8624ea";

export interface Filters {
  status: string;
  deliveryMethod: string;
}

export default function OrderList({
  orders,
  processOrders,
  processing,
  filters,
  setFilters,
  content,
}: {
  orders: RawOrders;
  processOrders: () => void;
  processing: boolean;
  filters: Filters;
  setFilters: (arg0: Filters) => void;
  content: Translation;
}) {
  const generateOrderPreviews = () =>
    orders.checkoutForms.map((order) => (
      <OrderPreview key={order.id} order={order} content={content} />
    ));

  const ORDER_STATUSES = {
    ALL: content.orders.filters.all,
    NEW: content.orders.filters.new,
  };
  const DELIVERY_METHODS = {
    ALL: content.orders.filters.all,
    [CASH_ON_DELIVERY_ID]: content.orders.filters.cashOnDelivery,
  };

  return (
    <>
      <div className="container toolbox">
        <div className="vertical container">
          <div className="container radio-container">
            {content.orders.filters.status}:
            {Object.entries(ORDER_STATUSES).map(([statusID, statusDesc]) => (
              <InputRadio
                key={statusID}
                value={statusID}
                currentValue={filters.status}
                onChange={(val: string) =>
                  setFilters({ ...filters, status: val })
                }
              >
                {statusDesc}
              </InputRadio>
            ))}
          </div>
          <div className="container radio-container">
            {content.orders.filters.deliveryMethod}:
            {Object.entries(DELIVERY_METHODS).map(([methodID, methodDesc]) => (
              <InputRadio
                key={methodID}
                value={methodID}
                currentValue={filters.deliveryMethod}
                onChange={(val: string) =>
                  setFilters({ ...filters, deliveryMethod: val })
                }
              >
                {methodDesc}
              </InputRadio>
            ))}
          </div>
        </div>
        <button
          className="btn2"
          disabled={
            filters.status !== "NEW" ||
            filters.deliveryMethod !== CASH_ON_DELIVERY_ID ||
            processing ||
            orders.count === 0
          }
          title={content.orders.processTooltip}
          onClick={processOrders}
        >
          {content.orders.processOrders}
        </button>
      </div>
      {orders.count > 0 ? (
        <h4>
          {content.orders.displayInfo} 1-{orders.count} {content.misc.of}{" "}
          {orders.totalCount}
        </h4>
      ) : (
        <>
          <h4 style={{ marginBottom: 0 }}>No orders to display.</h4>
          <p>There are no orders matching the search criteria specified.</p>
        </>
      )}
      <table className="order-list">
        <tbody>{generateOrderPreviews()}</tbody>
      </table>
    </>
  );
}
const OrderPreview = ({
  order: {
    id: orderID,
    buyer,
    payment,
    status,
    fulfillment,
    delivery,
    lineItems,
    summary,
  },
  content,
}: {
  order: Order;
  content: Translation;
}) => (
  <>
    <tr className="order-item">
      <td>
        {content.orders.orderDetails.id}: <CopyElem>{orderID}</CopyElem>
        <ul className="vertical container order-items">
          {lineItems.map((item) => (
            <li key={item.id}>
              <i className="container product-name">
                {item.offer.name}
                <span>
                  <small>{item.quantity}x</small> {item.price.amount}{" "}
                  <small>{item.price.currency}</small>
                </span>
              </i>
            </li>
          ))}
        </ul>
      </td>
    </tr>
    <tr className="container order-item">
      <td className="vertical container order-info buyer-info">
        <span className="order-label">{content.orders.orderDetails.buyer}</span>
        <small>
          {buyer.firstName} {buyer.lastName}
        </small>
        <CopyElem>{buyer.login}</CopyElem>
        <small>{buyer.phoneNumber}</small>
        <small>
          <CopyElem textToCopy={buyer.email}>
            {content.orders.orderDetails.copyEmail}
          </CopyElem>
        </small>
      </td>
      <td className="vertical container order-info delivery-details">
        <span className="order-label">
          {content.orders.orderDetails.delivery}
        </span>
        <CopyElem textToCopy={delivery.method.id}>
          {delivery.method.name}
        </CopyElem>
        <small>
          {content.orders.orderDetails.cost}: {formatPrice(delivery.cost)}
          {delivery.smart && <small> (ALLEGRO SMART)</small>}
        </small>
        <small>
          <b>Total: {formatPrice(summary.totalToPay)}</b>
        </small>
      </td>
      <td className="vertical container order-info">
        <span className="order-label">
          {content.orders.orderDetails.status}
        </span>
        {fulfillment.status}
        <small>{status}</small>
        {payment.finishedAt && (
          <small>
            {content.orders.orderDetails.payment}:{" "}
            {new Date(payment.finishedAt).toLocaleString()}
          </small>
        )}
      </td>
    </tr>
  </>
);
