import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import "./App.css";
import React, { useEffect, useState } from "react";
import { checkForCode, updateLoginStage } from "./misc/util";
import Lookup from "./pages/Lookup/Lookup";
import Home from "./pages/Home/Home";
import Orders from "./pages/Orders/Orders";
import ErrorPage from "./pages/Errors/ErrorPage";
import { Language, LoginStage, MessageCode, Translation } from "./misc/models";
import Message from "./components/Messages/Message";
import translationsEN from "./translations/english.json";
import translationsPL from "./translations/polish.json";

const ROUTES = { "/orders": Orders, "/lookup": Lookup };

const DEFAULT_LANGUAGE = (navigator.languages?.[0] ?? navigator.language ?? "")
  .toLowerCase()
  .includes("pl")
  ? Language.PL
  : Language.EN;

function App() {
  const [loginStage, setLoginStage] = useState(LoginStage.LOGGED_OUT);
  const [page, setPage] = useState<keyof Translation>("home");
  const [errorMessage, setErrorMessage] = useState<MessageCode>("");
  const [language, setLanguage] = useState<Language>(DEFAULT_LANGUAGE);

  useEffect(() => {
    updateLoginStage(setLoginStage);
    checkForCode(_setLoginStage);
    getUserLanguage();
  }, []);

  function _setLoginStage(stage: LoginStage) {
    if (stage === LoginStage.LOGIN_FAILED) {
      setErrorMessage("errLogin");
      setLoginStage(LoginStage.LOGGED_OUT);
    } else {
      setLoginStage(stage);
    }
  }

  function getUserLanguage() {
    const lang = +(localStorage.getItem("cocodentax-language") ?? "");
    if (!isNaN(lang) && lang in Language) {
      setLanguage(lang);
    } else {
      setUserLanguage(DEFAULT_LANGUAGE);
    }
  }

  function setUserLanguage(value: Language) {
    setLanguage(value);
    localStorage.setItem("cocodentax-language", `${value}`);
  }

  const loggedIn = loginStage === LoginStage.LOGGED_IN;

  const content = language === Language.PL ? translationsPL : translationsEN;

  return (
    <Router>
      <Header
        loginStage={loginStage}
        setLoginStage={setLoginStage}
        language={language}
        content={content}
        setLanguage={setUserLanguage}
      />
      <div className="vertical container">
        <main className="vertical container">
          <h2>{(content[page] as { title: string }).title}</h2>
          <Message type="error" msg={content.messages[errorMessage]} />
          <Routes>
            <Route
              path="/"
              element={
                <Home setPage={setPage} loggedIn={loggedIn} content={content} />
              }
            />
            {Object.entries(ROUTES).map(([path, component]) => (
              <Route
                key={path}
                path={path}
                element={
                  loggedIn ? (
                    React.createElement(component, { setPage, content })
                  ) : (
                    <ErrorPage
                      errorPage="unauthorised"
                      setPage={setPage}
                      content={content}
                    />
                  )
                }
              />
            ))}
            <Route
              path="/*"
              element={
                <ErrorPage
                  setPage={setPage}
                  errorPage="notFound"
                  content={content}
                />
              }
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
