import React from "react";

export default function InputBox({
  value,
  setValue,
  type = "text",
  children = null,
  className = "input-box container",
  onSubmit = () => null,
  autoFocus = false,
  disabled = false,
  tabIndex,
}: {
  value: string;
  setValue: (arg0: string) => void;
  children?: React.ReactNode;
  className?: string;
  type?: string;
  onSubmit?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  tabIndex?: number;
}) {
  const handleKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key !== "Enter") return;
    onSubmit();
  };

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setValue(evt.target.value);
  }

  return (
    <label className={className}>
      {children}
      <input
        autoFocus={autoFocus}
        disabled={disabled}
        type={type}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        tabIndex={tabIndex}
      />
    </label>
  );
}
