import { useEffect } from "react";
import { useState } from "react";
import InputRadio from "../../components/InputComponents/InputRadio";
import Message from "../../components/Messages/Message";
import { Translation } from "../../misc/models";
import { RawOrders } from "../../misc/models.allegro";
import { fetchWithToken } from "../../misc/util";
import OrderList, { CASH_ON_DELIVERY_ID } from "./OrderList";
import OrderProcessing from "./OrderProcessing";
import "./Orders.css";

export default function Orders({
  setPage,
  content,
}: {
  setPage: (arg0: keyof Translation) => void;
  content: Translation;
}) {
  const [showOrderList, setShowOrderList] = useState(true);
  const [orders, setOrders] = useState<RawOrders | null | undefined>(undefined);
  const [processing, setProcessing] = useState(false);

  const [filters, setFilters] = useState({
    status: "NEW",
    deliveryMethod: CASH_ON_DELIVERY_ID,
  });

  useEffect(() => {
    setPage("orders");
  }, [content]);

  useEffect(() => {
    fetchOrders(filters.status, filters.deliveryMethod);
  }, [filters]);

  function beginProcessingOrders() {
    setShowOrderList(false);
    setProcessing(true);
  }

  async function fetchOrders(
    statusFilter: string,
    deliveryMethodFilter: string
  ) {
    setOrders(undefined);
    let path = "order/checkout-forms";
    const query: Record<string, string> = {};
    if (statusFilter !== "ALL") {
      query["fulfillment.status"] = statusFilter;
    }
    if (deliveryMethodFilter !== "ALL") {
      query["delivery.method.id"] = deliveryMethodFilter;
    }
    if (Object.keys(query).length > 0) {
      path += "?" + new URLSearchParams(query).toString();
    }
    const res = await fetchWithToken("allegro", path);
    if (res) {
      setOrders(res.data);
    } else {
      setOrders(null);
    }
  }

  return (
    <>
      <p>{content.orders.body}</p>
      <div className="container radio-container tab-container">
        <InputRadio
          className="tabSelector"
          value={true}
          currentValue={showOrderList}
          onChange={setShowOrderList}
        >
          {content.orders.overview}
        </InputRadio>
        <InputRadio
          className="tabSelector"
          value={false}
          currentValue={showOrderList}
          onChange={setShowOrderList}
        >
          {content.orders.processing}
        </InputRadio>
      </div>
      {orders ? (
        <>
          <hr className="tab-separator" />
          {showOrderList ? (
            <OrderList
              content={content}
              orders={orders}
              processing={processing}
              processOrders={beginProcessingOrders}
              filters={filters}
              setFilters={setFilters}
            />
          ) : (
            <OrderProcessing
              orders={orders}
              processing={processing}
              setProcessing={setProcessing}
              content={content}
            />
          )}
        </>
      ) : orders === undefined ? (
        <div className="spaced-out">
          <Message type="info" msg={content.misc.loading + "..."} />
        </div>
      ) : (
        <>
          <div className="spaced-out vertical container">
            <Message type="error" msg={content.messages.errGetOrders} />
          </div>
          <button className="btn2" onClick={() => window.location.reload()}>
            {content.misc.reload}
          </button>
        </>
      )}
    </>
  );
}
