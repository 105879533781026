import { copyToClipboard } from "../../misc/util";
import { MdCheck, MdContentCopy } from "react-icons/md";
import "./CopyElem.css";
import { useState } from "react";

export default function CopyElem({
  children,
  textToCopy,
}: {
  children: string | null;
  textToCopy?: string;
}) {
  const [clicked, setClicked] = useState(false);

  const _textToCopy = textToCopy ?? children ?? "";

  function handleClick() {
    if (clicked) return;
    if (!copyToClipboard(_textToCopy)) return;
    setClicked(true);
    setTimeout(() => setClicked(false), 5000);
  }
  let className = "clickable copy-elem";
  if (clicked) className += " clicked";
  return (
    <span
      className={className}
      onClick={handleClick}
      title={`Copy '${_textToCopy}' to clipboard`}
    >
      {children}
      {clicked ? <MdCheck /> : <MdContentCopy size="17px" />}
    </span>
  );
}
