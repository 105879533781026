import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Translation } from "../../misc/models";

export default function ErrorPage({
  errorPage,
  setPage,
  content,
}: {
  errorPage: "notFound" | "unauthorised";
  setPage: (arg0: keyof Translation) => void;
  content: Translation;
}) {
  useEffect(() => {
    setPage(errorPage);
  }, []);

  return (
    <>
      <p>
        {content[errorPage].bodyA + " "}
        <code>{window.location.pathname}</code>
        {content[errorPage].bodyB}
      </p>
      <Link className="btn2 nav-item" to="/">
        {content.misc.returnToHomepage}
      </Link>
    </>
  );
}
