import React from "react";
import "./InputComponents.css";

export default function InputRadio<T extends string | number | boolean>({
  value,
  currentValue,
  onChange,
  children = null,
  className = "clickable radio-label",
}: {
  value?: T;
  currentValue: T;
  onChange: Function;
  children?: React.ReactNode;
  className?: string;
}) {
  const _value = value ?? (children || "").toString();

  function handleClick() {
    onChange(_value);
  }

  const checked = currentValue === _value;
  if (checked) className += " checked";

  return (
    <label className={className}>
      {children}
      <input type="radio" checked={checked} onChange={handleClick} />
    </label>
  );
}
