import { Link, useLocation } from "react-router-dom";
import {
  Language,
  LANGUAGES,
  LoginStage,
  Translation,
} from "../../misc/models";
import { startLogin, logout } from "../../misc/util";
import "./Header.css";

export default function Header({
  loginStage,
  setLoginStage,
  language,
  content,
  setLanguage,
}: {
  loginStage: LoginStage;
  setLoginStage: (arg0: LoginStage) => void;
  language: Language;
  content: Translation;
  setLanguage: (arg0: Language) => void;
}) {
  const location = useLocation();

  const NAV_ITEMS = {
    "/orders": content.orders.title,
    "/lookup": content.lookup.title,
  };

  return (
    <header className="container">
      <Link to="/">
        <h1>Cocodentax Admin</h1>
      </Link>
      {loginStage === LoginStage.LOGGED_IN &&
        Object.entries(NAV_ITEMS).map(([path, desc]) => {
          const active =
            location.pathname === path ||
            location.pathname.startsWith(path + "/");
          let className = "btn2 nav-item";
          if (active) {
            className += " active";
          }
          return (
            <Link className={className} key={path} to={path}>
              {desc}
            </Link>
          );
        })}
      <LangSelect language={language} setLanguage={setLanguage} />
      {loginStage === LoginStage.LOGGED_IN ? (
        <button className="btn2" onClick={() => logout(setLoginStage)}>
          {content.misc.logout}
        </button>
      ) : (
        <button
          className="btn2 allegro-login container"
          onClick={startLogin}
          disabled={loginStage === LoginStage.LOGGING_IN}
        >
          <img
            src="https://manager.paczkomaty.pl/assets/images/allegro.png"
            alt="shopping-basket"
          />
          {loginStage === LoginStage.LOGGING_IN ? (
            <div>{content.misc.loggingIn}</div>
          ) : (
            <div>
              {content.misc.loginWith}
              <br />
              Allegro
            </div>
          )}
        </button>
      )}
    </header>
  );
}

function LangSelect({
  language,
  setLanguage,
}: {
  language: Language;
  setLanguage: (arg0: Language) => void;
}) {
  return (
    <div className="clickable lang-select container">
      {LANGUAGES.map((lang) => (
        <span
          key={lang}
          className={`status lang-option ${
            lang === language ? "active " : ""
          }container`}
          onClick={() => setLanguage(lang)}
        >
          {Language[lang].toString()}
        </span>
      ))}
    </div>
  );
}
