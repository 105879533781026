import { MdCheck, MdClose, MdOutlineInfo } from "react-icons/md";
import "./Messages.css";

export type MessageType = "info" | "error" | "success";

export default function Message({
  type,
  msg,
}: {
  type?: MessageType;
  msg?: string;
}) {
  if (!msg || !type) return null;
  return (
    <div className={`container msg msg-${type}`}>
      {type === "success" ? (
        <MdCheck />
      ) : type === "error" ? (
        <MdClose />
      ) : (
        <MdOutlineInfo />
      )}
      {msg}
    </div>
  );
}
