import { MdCheck, MdClose } from "react-icons/md";
import { BiHourglass } from "react-icons/bi";

const classNames: { [key: string]: string } = {
  true: "success",
  false: "error",
};

export default function StatusIndicator({
  status,
}: {
  status: boolean | null | undefined;
}) {
  const partialClassName = classNames?.[status?.toString() ?? ""] ?? "info";
  return (
    <div className={`status container msg-${partialClassName}`}>
      {status ? <MdCheck /> : status === false ? <MdClose /> : <BiHourglass />}
    </div>
  );
}
