import { useEffect } from "react";
import { Translation } from "../../misc/models";
import "./Home.css";

export default function Home({
  loggedIn,
  setPage,
  content,
}: {
  loggedIn: boolean;
  setPage: (arg0: keyof Translation) => void;
  content: Translation;
}) {
  useEffect(() => {
    setPage("home");
  }, []);

  return (
    <>
      <p>{content.home.body[loggedIn ? "loggedIn" : "loggedOut"]}</p>
    </>
  );
}
