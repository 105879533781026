import { LoginStage } from "./models";
import { DeliveryAddress, LineItem } from "./models.allegro";

const CLIENT_ID = "ac40f38f68e142faa814b2f003549d0f";
const ALLEGRO_LOGIN_URL = `https://allegro.pl/auth/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=`;

export const POCZTEX_API_BASE = "https://e-nadawca.poczta-polska.pl/api/v2";

const USE_LOCAL_API_URL = false;
export const API_BASE =
  (process.env.NODE_ENV === "development" && USE_LOCAL_API_URL
    ? "http://localhost:3030"
    : "https://api.guzek.uk") + "/cocodentax-admin";

const ADDRESS_PATTERN =
  /^(?:(?:ul|al|os|pl)[.\s])?\s*(?<street>.+?),?\s*(?<houseNumber>\d+)\s*(?<houseLetter>[a-z]+)?(?:\s*[/m]\s*(?<apartment>[\d/]+[a-z]*))?\.?$/;

type SetLoginStage = (arg0: LoginStage) => void;

interface ConsumedResponse extends Response {
  data?: any;
}

function setExpiryDate(service: string, expiresIn: number) {
  const tokenExpiryDate = new Date().getTime() + expiresIn * 1000;
  localStorage.setItem(
    `tokenExpiryDate_${service}`,
    tokenExpiryDate.toString()
  );
}

async function fetchAllegroTokens(
  query: string,
  setLoginStage?: SetLoginStage
) {
  let res;
  try {
    res = await fetch(`${API_BASE}/allegro/auth${query}`);
  } catch {
    throw Error("Could not fetch Allegro tokens from the Auth API.");
  }
  const body = await res.json();

  if (!res.ok || !body.access_token || !body.refresh_token) {
    logout(setLoginStage);
    throw Error(
      `Invalid response from Allegro: ${body.error_description} (${body.error})`
    );
  }
  localStorage.setItem("accessToken_allegro", body.access_token);
  localStorage.setItem("refreshToken", body.refresh_token);
  setExpiryDate("allegro", body.expires_in);
  updateLoginStage(setLoginStage);
}

export async function checkForCode(setLoginStage: SetLoginStage) {
  if (!window.location.search) return;
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");
  if (!code) return;
  setLoginStage(LoginStage.LOGGING_IN);
  searchParams.delete("code");
  const path =
    window.location.origin + window.location.pathname + searchParams.toString();
  window.history.pushState({ path }, "", path);
  try {
    await fetchAllegroTokens(`?code=${code}`, setLoginStage);
  } catch (error) {
    console.error((error as Error).message);
    setLoginStage(LoginStage.LOGIN_FAILED);
  }
}

export function updateLoginStage(setLoginStage?: SetLoginStage) {
  if (!setLoginStage) return;
  const loggedIn = !!localStorage.getItem("accessToken_allegro");
  if (loggedIn) {
    setLoginStage(LoginStage.LOGGED_IN);
  } else {
    setLoginStage(LoginStage.LOGGED_OUT);
  }
}

export function startLogin() {
  const redirectURI =
    window.location.host === "admin.guzek.uk"
      ? window.location.href
      : "https://www.guzek.uk/cocodentax-admin" + window.location.search;
  window.location.href = ALLEGRO_LOGIN_URL + redirectURI;
}

export function logout(setLoginStage?: SetLoginStage) {
  localStorage.removeItem("accessToken_allegro");
  localStorage.removeItem("refreshToken");
  updateLoginStage(setLoginStage);
}

/** Returns the response body obtained from an API request to the specified path. */
export async function fetchWithToken(
  service: string,
  path: string,
  method = "GET",
  body?: object
) {
  const tokenExpiryDate = +(
    localStorage.getItem("tokenExpiryDate_" + service) ?? 0
  );
  const now = new Date().getTime();
  if (now > tokenExpiryDate) {
    if (service === "allegro") {
      const refreshToken = localStorage.getItem("refreshToken");
      try {
        await fetchAllegroTokens(`?token=${refreshToken}`);
      } catch (error) {
        console.error((error as Error).message);
        return null;
      }
    } else {
      const res = await fetch(POCZTEX_API_BASE + "/oauth2/enki-token", {
        method: "POST",
      });
      const tokenData = await res.json();
      if (res.ok) {
        localStorage.setItem("accessToken_pocztex", tokenData.access_token);
        setExpiryDate("pocztex", tokenData.expires_in);
      }
    }
  }
  const url =
    service === "allegro"
      ? `${API_BASE}/allegro/api/${encodeURIComponent(path)}`
      : /^https?:\/\//.test(path)
      ? path
      : `${POCZTEX_API_BASE}/${path}`;
  const accessToken = localStorage.getItem("accessToken_" + service);
  const requestOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    method,
  };
  if (method !== "GET" && body) {
    requestOptions.body = JSON.stringify(body);
  }
  let res: ConsumedResponse;
  try {
    res = await fetch(url, requestOptions);
  } catch {
    return null;
  }
  try {
    res.data = await res.json();
  } catch {}
  return res;
}

export function copyToClipboard(text: string) {
  try {
    navigator.clipboard.writeText(text);
  } catch {
    console.error("Could not copy to clipboard.");
    return false;
  }
  return true;
}

export const formatErrorMessage = (serverResponse: Record<string, string>) =>
  Object.entries(serverResponse)
    .map(([code, desc]) => `${code}: ${desc}`)
    .join("\n");

export function getOrderDescription(lineItems: LineItem[]) {
  let count = 0;
  // Iterate through each item bought in the order
  for (const item of lineItems) {
    // Check if the item offer name starts with a quantity (e.g. 1x, 2X etc.)
    const match = /^(?<quantity>\d+)x/i.exec(item.offer.name);
    const quantity = match?.groups?.quantity;
    if (quantity) {
      count += +quantity;
    } else {
      count += 1;
    }
  }
  return `Zamówienie ALLEGRO ${count} szt.`;
}

export function getRecipientData(address: DeliveryAddress, email: string) {
  if (!address) {
    throw Error("No delivery address specified!");
  }
  // Extract the house and apartment number from the RegEx
  const match = ADDRESS_PATTERN.exec(address.street);
  return {
    name: `${address.firstName} ${address.lastName}`,
    company: address.companyName ?? "",
    street: match?.groups?.street,
    house: match?.groups?.houseNumber ?? "" + match?.groups?.houseLetter ?? "",
    apartment: match?.groups?.apartment,
    place: address.city,
    // Remove '-' from post code
    postalCode: address.zipCode?.replace("-", ""),
    countryIsoAlfa2Code: address.countryCode,
    // Remove spaces and '+' from phone number
    phoneNumber: address.phoneNumber?.replace(/\s|\+/g, ""),
    email,
  };
}

export const filterObjectByKey = <T extends object>(obj: T, key: keyof T) =>
  Object.fromEntries(
    Object.entries(obj).filter(([currKey]) => currKey !== key)
  );

export const formatPrice = ({
  amount,
  currency,
}: {
  amount: string | number;
  currency: string;
}) => `${amount} ${currency}`;
